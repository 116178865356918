import React , {Component} from 'react';
import './clients.css';
import nike from '../../assets/images/Website-Icons/11.svg';
import coca from '../../assets/images/Website-Icons/8.svg';
import zurich from '../../assets/images/Website-Icons/9.svg';
import macdonalds from '../../assets/images/Website-Icons/10.svg';
import starbucks from '../../assets/images/Website-Icons/4.svg';
import star from '../../assets/images/Website-Icons/5.svg';

import Carousel from 'nuka-carousel';

class Clients extends Component {

    render() {
        return (
            <div className="clients">
                <div className="clients-slide">
                        <div>
                            <a href=""><img src={nike}   alt="Nike-logo" /></a>
                            <span className="top-left"></span>
                            <span className="bottom-right"></span>
                        </div>
                        <div className="tweak-1">
                           <a href=""><img src={coca} className="img-client"  alt="Zurich-logo" /></a>
                           <span className="bottom-left"></span>
                            <span className="bottom-right"></span>
                            <span className="interface_top"></span>
                        </div>
                        <div>
                           <a href=""><img src={zurich} className="img-client"  alt="Starbucks-logo" /></a>
                           <span className="top-right"></span>
                            <span className="bottom-left"></span>
                        </div>
                    
                         <div>
                            <a href=""><img src={macdonalds} className="img-client"  alt="Macdonald's-logo" /></a>
                            <span className="top-right"></span>
                            <span className="bottom-left"></span>
                         </div>
                         <div className="tweak-2">
                             <a href=""><img src={starbucks} className="img-client"  alt="embassy-direct-logo" /></a> 
                             <span className="top-left"></span>
                            <span className="top-right"></span>
                            <span className="marketing_bottom"></span>
                         </div>
                         <div>
                            <a href=""><img src={star} className="img-client"  alt="Skwilapp-logo" /></a>
                            <span className="top-left"></span>
                            <span className="bottom-right"></span>
                         </div>
                   
                           
                    </div>
                    
                    
                    <Carousel withoutControls={true} autoplay={true} autoplayInterval={1500} 
                     speed={800}  cellSpacing={0} slidesToShow={3}
                     slidesToScroll={1} wrapAround={true} cellAlign="center">
                        <div className="carousel-item">
                            <a href=""><img src={nike} id="nike"  alt="Nike-logo" /></a>
                        </div>
                        <div className="carousel-item">
                           <a href=""><img src={coca} id="coca"  alt="Zurich-logo" /></a>
                        </div >
                        <div className="carousel-item">
                           <a href=""><img src={zurich}  id="zurich" alt="Starbucks-logo"/></a>
                        </div>
                    
                         <div className="carousel-item">
                            <a href=""><img src={macdonalds} id="macdo"  alt="Macdonald's-logo" /></a>
                         </div>
                         <div className="carousel-item">
                             <a href=""><img src={starbucks}  alt="embassy-direct-logo" /></a> 
                         </div >
                         <div className="carousel-item">
                            <a href=""><img src={star}  alt="Skwilapp-logo" /></a>
                         </div>
                   
                            
                    </Carousel> 
                    
           
                
                
            </div>
               
        );
    }
}

export default Clients;