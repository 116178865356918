import React , {Component} from 'react';
import './aboutSection.css';


class AboutSection extends Component {
    
    
    render(){
        return (
            <div name="blrDiv" className=" section AboutSection-container" style={{backgroundImage : `url(${this.props.image})`}}>

               
                    <div className="AboutSection-container-description"  >
                    <div >
                        <h4>{this.props.title}</h4>
                        <span>{this.props.subTitle}</span>
                        <p>{this.props.description}</p>
                    </div>


                </div>

            </div>
        );
    }
}

export default AboutSection;