import React , {Component} from 'react';
import './HomeSection.css';
import Button from '../Button/Button';

class HomeSection extends Component {
    
    state={
        animated : true
    }
    toggleAnimate = () => {
        if(this.state.animated){
            this.setState({animated : false});
        }else{
            this.setState({animated : true});
        }
    }
    render(){
        
        let img = !this.props.bgImg ? null : this.props.bgImg;
        let title = !this.props.title ? null : <h1>{this.props.title}</h1>;
        let p = !this.props.paragraph ? null : <p name="para" className={this.props.pClass}>{this.props.paragraph}</p>;
        let background = !this.props.bgImg ? null : <div id={"bgImg-" +this.props.id } className="bgImg" style={{background : 'url(' +  img + ')'}}></div>;
        let expertise = this.props.expertise ?  ( 
        <div className="Expertise">
                <div className="Exp_block Brand">
                    <h4>Stratégie</h4>
                    <p>Recherche d'utilisateurs</p>
                    <p>Pensée de conception</p>
                    <p>Analyse paysage &amp; marché</p>
                    <p>Métriques &amp; analyses</p>
                    <p>Recherche technologique &amp; planification</p>
                    <p>Consultation stratégique continue</p>
                    <span className="top-left"></span>
                    <span className="bottom-right"></span>
                </div>
                <div className="Exp_block Interface">
                    <h4>Design d'interface</h4>
                    <p>Design Responsive</p>
                    <p>Applications Web</p>
                    <p>Applications Mobile</p>
                    <p>Animation UI &amp; Prototype</p>
                    <p>Design d'interaction</p>
                    <span className="bottom-left"></span>
                    <span className="bottom-right"></span>
                    <span className="interface_top"></span>
                </div>
                <div className="Exp_block Experience">
                    <h4>Design d'expérience</h4>
                    <p>Recherche</p>
                    <p>Design Responsive</p>
                    <p>Prototypage rapide</p>
                    <p>Prototypage Animé</p>
                    <p>Design d'interaction</p>
                    <p>Tests d'utilisation</p>
                    <span className="top-right"></span>
                    <span className="bottom-left"></span>
                </div >
                <div className="Exp_block Technology">
                    <h4>Technologie</h4>
                    <p>Développement site web</p>
                    <p>Développemenst Ecommerce</p>
                    <p>Application Web</p>
                    <p>Application Mobile</p>
                    <p>Intégrations API</p>
                    <p>Technologie hybride</p>
                    <p>Implémentation du CMS</p>
                    <span className="top-right"></span>
                    <span className="bottom-left"></span>
                </div>
                <div className="Exp_block Marketing">
                    <h4>Branding</h4>
                    <p>Identité visuelle</p>
                    <p>Directives de la marque</p>
                    <p>Actifs de conception</p>
                    <p>Direction artistique</p>
                    <p>Motion Graphics &amp; Animation</p>
                    <p>Emballage &amp; impression</p>
                    <span className="top-left"></span>
                    <span className="top-right"></span>
                    <span className="marketing_bottom"></span>
                </div>
                <div className="Exp_block Digital">
                    <h4>Marketing</h4>
                    <p>Marketing des médias sociaux</p>
                    <p>gestion des médias sociaux</p>
                    <p>Gestion communauté</p>
                    <p>Planning marketing</p>
                    <p>Gestion de campagne</p>
                    <p>Création de contenu</p>
                    <p>Marketing intégré</p>
                    <span className="top-left"></span>
                    <span className="bottom-right"></span>
                </div>
            </div>):null;
        
        return (
            <div id={this.props.id} className={"HomeSection section " + this.props.id}>
                <div className="div">
                    
                    {title}
                    {p}
                    {/*this.props.expertise ? null : <Button btnClasse={this.props.btnClasse} btnText={this.props.btnText}  />*/}
                    {background}
                    {expertise}
                </div>
                
            </div>
        );
    }
}

export default HomeSection;