import React , {Component} from 'react';
import './paginate.css';
import scrollDown from '../../assets/images/Website-Icons/ScrollDown.svg';
import scrollUp from '../../assets/images/Website-Icons/ScrollUp.svg';
class Paginate extends Component {
    
    state = {
        dots : []
    }
    add = () => {
        let array = [];
        for (let index = 0; index < this.props.nbDots; index++) {
            if(index ==0){
                array.push(<span key={index} name="dots" id={"paginate-dot-" + index} className="selected-dot"  onClick={(event) => this.props.myFunction(index+1)}></span>);
            }else {
                array.push(<span key={index} name="dots" id={"paginate-dot-" + index}  onClick={(event) => this.props.myFunction(index+1)}></span>);
            }
        }
        return array;
    }

   
   
    render(){
        
        
        return (
            <div className="Paginate" id="paginate">
                <div className="paginate-space-dots-1" name="blrDiv"></div>
                <div className="paginate-space-dots-2" name="blrDiv"></div>
                <div className="dots-centered">
                    <div className="paginate-space-1" name="blrDiv"></div>
                    <div className="Paginate-dots">
                            {this.add()} 
                    </div>
                    <div className="paginate-space-2" name="blrDiv"></div>
               </div>
               <div id="p-triangle1" className="Paginate-triangle" onClick={(event) =>{  this.props.fullpage(event)} }>
                   <img src={scrollDown} />
                  
               </div>
               <div id="p-triangle2" className="Paginate-triangle" onClick={this.props.reverse} >
                    <img src={scrollUp} />
               </div>
               <div className="paginate-space-3" name="blrDiv"></div>
            </div>
        );
    }
}

export default Paginate;