import React , {Component} from 'react';
import './Button.css';
class Button extends Component {

    render() {
        let button = this.props.btnText ? 'block' : 'none';
        return (
            <div style={{display : button}} className={"Button " + this.props.btnClasse  } onClick={this.props.clicked}>
                <span className="top-left"></span>
                <span className="top-right"></span>
                <span className="bottom-left"></span>
                <span className="bottom-right"></span>
                <a href={this.props.link} target={this.props.btnClasse === 'btnContact' ? '' : '_blank' }>{this.props.btnText}</a>
            </div>
        );
    }
}

export default Button;