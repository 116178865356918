import React , {Component} from 'react';
import './socialMedia.css';
import behance from '../../assets/images/Website-Icons/behance.svg';
import dribble from '../../assets/images/Website-Icons/dribbble.svg';
import likedin from '../../assets/images/Website-Icons/linkedin.svg';
import twitter from '../../assets/images/Website-Icons/twitter.svg';
import facebook from '../../assets/images/Website-Icons/facebook.svg';
import insta from '../../assets/images/Website-Icons/instagram-logo.svg';
class socialMedia extends Component {
   
    render() {
       
        return (
            <div className="socialMedia" id="socialMedia"> 
                    <a href="https://dribbble.com/Gudiz" className="anchorAnimate" target="_blank" ><img src={dribble} alt="dribble-logo"/></a>
                    <a href="https://www.behance.net/gudiz/" className="anchorAnimate" target="_blank"><img src={behance} alt="behance-logo"/></a>
                    <a href="https://www.linkedin.com/company/gudiz" className="anchorAnimate" target="_blank"><img src={likedin} alt="linkedIn-logo"/></a>
                    <a href="https://www.instagram.com/gudiz.co/" className="anchorAnimate" target="_blank"><img src={insta} alt="instagram-logo"/></a>
                    <a href="https://twitter.com/gudizco" className="anchorAnimate" target="_blank"><img src={twitter} alt="twitter-logo"/></a>
                    <a href="https://www.facebook.com/gudiz.co" className="anchorAnimate" target="_blank"><img src={facebook} alt="facebook-logo"/></a>
            </div>
        );
    }
}

export default socialMedia;