import React , {Component} from 'react';
import './workProject.css';


class WorkProject extends Component {
    
    
    render(){
        return (
            <div className="WorkProject-container" onClick={this.props.clicked}>

                <div className="WorkProject-container-flow-bg" style={{backgroundImage : `url(${this.props.image})`}}></div>
                    <div className="WorkProject-container-flow"  >
                    <div>
                        <h4>{this.props.title}</h4>
                        <p>{this.props.description}</p>
                    </div>


                </div>

            </div>
        );
    }
}

export default WorkProject;