import React , {Component} from 'react';
import './contact.css';
import Button from '../Button/Button';
import axios from 'axios';
class Contact extends Component {
    state = {
        inputs : {
            name : {
                value : "",
                placeholder : "Nom complet",
                visiblePlaceHolder : true,
                validation : {
                    isRequired : true,
                    minLength : 2,
                    valid : false
                }
            },
            email : {
                value : "",
                placeholder : "Adresse email",
                visiblePlaceHolder : true,
                validation : {
                    isRequired : true,
                    isEmail : true,
                    valid : false
                }
            },
            message : {
                value : "",
                placeholder : "Dites-nous ce que nous pouvons faire pour vous…",
                visiblePlaceHolder : true,
                validation : {
                    isRequired : true,
                    minLength : 3,
                    valid : false
                }
            }
        },
        isValid : false,
        sent : false
    }
    verify = (value , rules) => {
        let isvalid = true;
        if(rules.isRequired){
            isvalid = value.trim() !== '' && isvalid ;
        }
        if(rules.minLength){
            isvalid = value.length >= rules.minLength && isvalid;
        }
        if(rules.isEmail){
            isvalid = value.match(/^[a-zA-Z0-9_\.%\+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,}$/) && isvalid;
        }
        return isvalid;
    }
    
    send = () => {
        if(this.state.isValid && !this.state.sent){
           axios({
                method: 'post',
                url : "https://www.gudiz.co/emailSender.php",
                data: {
                    email : this.state.inputs.email.value,
                    name:this.state.inputs.name.value ,
                    message: this.state.inputs.message.value
                }
            }).then(res =>{
                console.log(res.status);
                this.setState({sent : true})
            });
            
        }else {
            //alert(false);
        }
    }
    
    changeHandler = (event) => {
        const form = {...this.state.inputs};
        const element = {...form[event.target.id]};
        element.value = event.target.value;
        element.validation.valid = this.verify(element.value,element.validation);
        form[event.target.id] = element;
        let valid = true;

        for(let el in form ){
            valid = form[el].validation.valid && valid;
        }
        this.setState({inputs : form , isValid : valid});
    }

    onFocus = (event) =>{
        const form = this.state.inputs;
        let element = form[event.target.id];
        element.visiblePlaceHolder = false;
        document.getElementById(event.target.id).classList.toggle("selectedText")
        this.setState({inputs : form });
    }
    onBlur = (event) =>{
        const form = this.state.inputs;
        let element = form[event.target.id];
        element.visiblePlaceHolder = true;
        document.getElementById(event.target.id).classList.remove("selectedText")
        this.setState({inputs : form });
    }

    
    render(){
      
        let namePlaceHolder = this.state.inputs.name.visiblePlaceHolder ? this.state.inputs.name.placeholder : "";
        let emailPlaceHolder = this.state.inputs.email.visiblePlaceHolder ? this.state.inputs.email.placeholder : "";
        let messagePlaceHolder = this.state.inputs.message.visiblePlaceHolder ? this.state.inputs.message.placeholder : "";
        let intouchClass = ["in-Touch",this.props.fromWork ? "animate-intouch workContactResp" : ""]
        let formClass = ["form",this.props.fromWork ? "animate-form" : ""]
        let containerClass = ["HomeSection section ", this.props.fromAbout ? "aboutTest " : "",this.props.fromWork ? "workcontainerResp " : ""]
        return (
            <div id={this.props.id} name="blrDiv" className={containerClass.join(' ')+ this.props.id}>
                <div className="div">
                    
                    <div className="contact">
                        <div className={intouchClass.join(' ')} id="in-touch">
                            <h1 id="contactH1">
                                <span className="fixed"><span>Contactez</span></span>
                                <span className="fixed"><span>nous</span></span>
                               
                             </h1>
                                <div className="info" >
                                    <span>Appelez-nous.</span>
                                    <p>+212 808548788</p>
                                </div>
                                <div className="info" >
                                    <span>Rendez-nous visite.</span>
                                    <p>Rue ibnou Sina, le noyer, Imm D N°13, gueliz. Marrakech</p>
                                </div>
                                <Button  btnText="Localisation" btnClasse="GetLocation" link="https://www.google.com/maps/place/Gudiz+-+Agence+de+communication/@31.6349596,-8.0121991,17z/data=!3m1!4b1!4m5!3m4!1s0xdafee4000000037:0xb061b54989c91599!8m2!3d31.6349551!4d-8.0100104"/>
                                <Button  btnText="Contact" btnClasse="BtnContact" link="mailto:hi@gudiz.co"/>
                        </div>
                        <div className={formClass.join(' ')} id="form" >
                            
                            <div className="form-control">
                                <label>Votre nom</label>
                                <input type="text" id="name" autoComplete="off" required placeholder={namePlaceHolder}  onBlur={(event)=>this.onBlur(event)} onFocus={(event)=>this.onFocus(event)} onChange={(event) => this.changeHandler(event)}/>
                            </div>
                            <div className="form-control">
                                <label>Votre email</label>
                                <input type="email" id="email"  autoComplete="off" required placeholder={emailPlaceHolder} onBlur={(event)=>this.onBlur(event)} onFocus={(event)=>this.onFocus(event)}  onChange={(event) => this.changeHandler(event)}/>
                            </div>
                            <div className="form-control">
                                <label>Comment pouvons-nous vous aider ?</label>
                                <textarea placeholder={messagePlaceHolder} autoComplete="off"  required id="message" onBlur={(event)=>this.onBlur(event)} onFocus={(event)=>this.onFocus(event)} onChange={(event) => this.changeHandler(event)}></textarea>
                            </div>
                            <Button  btnText="Envoyer" btnClasse="Send" clicked={this.send} />
                          
                        </div>
                    </div>
                    
                </div>
                {
                    this.props.fromAbout &&
                    <div className="contact-bg-bottom">
                    <div></div>
                    <span>Gudiz Studio &copy; 2019</span>
                    <div></div>
               
                    </div>
                }
               
            </div>
        );
    }
}

export default Contact;