import React , {Component} from 'react';
import './skeleton.css';


import Header from '../Header/header.js'
import Container from '../container/container';
import Paginate from '../paginate/paginate';
import HomeSection from '../HomeSection/HomeSection';
import SlideHome from '../../assets/images/slide-home.png';
import SocialMedia from '../socialMedia/socialMedia';
import ClientsReviews from '../clientsReviews/clientsReviews';
import Contact from '../Contact/contact';

class Skeleton extends Component {
    state = {
        nbDots : 0
    }
    
    myFunction = (nb) => {
        if(this.state.nbDots === 0){
            this.setState({nbDots : nb});
        } 
    }
    getRef = () => {
        let percent = 100 / (this.container.page.state.sectionCount -1);
        let page = this.container.page.fullpageApi.getActiveSection();
        
        if((page.index + 1) != this.container.page.state.sectionCount ){
            let v = (page.index + 1) * percent ;
            document.body.style.backgroundPosition = "0% " + v +"%";
            this.container.page.fullpageApi.moveTo(page.index +2);
        }
    }
    
    reverse = () => {
     
        this.container.page.fullpageApi.silentMoveTo(1);
        this.toggleDots(0);
        this.getRef();
        this.container.page.fullpageApi.moveSectionUp();
        let socialMedia = document.getElementById('socialMedia');
        socialMedia.classList.replace('animateSocialMediaReverse','animateSocialMedia');
    }
    moveToSection = (section) => {
        this.container.page.fullpageApi.moveTo(section);
        this.toggleDots(section -1);
    }
    toggleDots = ( id) => {
        let dots = document.getElementsByName('dots');
        for (let index = 0; index < dots.length; index++) {
            dots[index].classList.remove('selected-dot');
        }
        
            let dot = "paginate-dot-" + id;
            document.getElementById(dot).classList.toggle('selected-dot');
        
    }


    render(){
      
        return (
            <div className="Skeleton">
                <Header  container={this.container} refresh={this.reverse}/>
                <div className="Skeleton-left" name="blrDiv"></div>
                {/* Containers of sections */}
                <Container myFunction={this.myFunction} reviews={this.clientsReviews} animateDots={this.toggleDots} moveBg={this.getRef} ref={ref => this.container = ref}>
                   <HomeSection id="home-1" 
                   /* title="<span className='text-flip'>We are a young and dynamic team</span><span className='text-flip'> of design, developing</span><span className='text-flip'> and marketing experts.</span>" */

                   title={[<span key={1} className="fixed"><span className='text-flip'>Notre équipe talentueuse,</span></span> ,
                   <span key={2} className="fixed"><span   className='text-flip'> utilise le meilleur dans   </span></span>,
                   <span key={3} className="fixed"><span   className='text-flip'>le digital pour donner </span></span>,
                   <span key={4} className="fixed"><span   className='text-flip'>vie à vos projets.</span></span>, ]}

                   paragraph="Une équipe motivée, ambitieuse, dotée d'un grand potentiel dans les outils digitaux, travaille en permanence pour répondre à vos exigences." 
                   pClass="paragraph-disapear"
                   btnText="Learn More" btnClasse="Learn-More"
                />


                   <HomeSection id="home-2" 
                   title={[<span key={6} className="fixed"><span>Réussissez votre   </span></span> ,
                   <span key={7} className="fixed"><span>transformation digitale, </span></span> ,
                   
                   <span key={8} className="fixed"><span>avec l’accélérateur de </span></span>,
                   <span key={9} className="fixed"><span>  performance Gudiz.</span></span> ]}
                   /* title="We create seamless experiences across all devices."  */
                   bgImg={SlideHome} 
                   paragraph="Notre équipe dispose d’une grande expérience dans le digital, vous accompagnera vers la réussite."
                   btnText="Explore" btnClasse="Explore"/>
                   <HomeSection id="home-3" expertise={true}/>
                   <ClientsReviews id="home-4" ref={ref => this.clientsReviews = ref}/>
                   <Contact id="home-5" ref={ref => this.contact = ref} container={this.container}/>
                </Container>
                {/* End of Containers of sections */}
                <Paginate fullpage={this.getRef} reverse={this.reverse} nbDots={this.state.nbDots} myFunction={this.moveToSection} currentContainer={this.container} />
                
                <div className="Skeleton-bottom">
                    <div className="bottom-border" name="blrDiv"></div>
                    <SocialMedia />
                    <span id="Skeleton-bottom-span">Gudiz Studio &copy; 2019</span>
                    <div className="bottom-border border-2" name="blrDiv">
                        <div name="blrDiv"></div>
                        <div name="blrDiv"></div>
                        <div name="blrDiv"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Skeleton;