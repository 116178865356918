import React , {Component} from 'react';
import './container.css';
import ReactFullpage from '@fullpage/react-fullpage';



class Container extends Component {

    
    applyTextRotation = (direction ,list) => {
        this.removeClasses(direction);
        this.removeClasses(direction);
        this.removeClasses(direction);
        this.removeClasses(direction);
        this.removeClasses(direction);
        this.removeClasses(direction);
        this.removeClasses(direction);
        if(direction === 'down'){
            for (let index = 0; index < list.length; index++) {
                setTimeout(() => {
                    if(list[index].tagName === 'SPAN'){
                    list[index].children[0].classList.toggle('text-flip');
                }
                },index *100);
            }
           
        }else {
            for (let index = 0; index < list.length; index++) {
                if(list[index].tagName === 'SPAN'){
                list[index].children[0].classList.toggle('text-flip-reverse');
                }
            }
        }
    }


    removeClasses = (direction) => {
        let list = document.getElementsByClassName('text-flip');
        let list2 = document.getElementsByClassName('text-flip-reverse');
        if(direction != 'down'){
            let list1 = document.getElementsByClassName('position-reverse');
     
            for (let index = 0; index < list1.length; index++) {
                
                    list1[index].classList.remove('position-reverse'); 
   
            }
            
            
        }
        for (let index = 0; index < list.length; index++) {
            if(direction === 'down'){
                if(list[index].tagName === 'SPAN'){
                    list[index].classList.toggle('position-reverse');
                    list[index].classList.remove(['text-flip']);
                }
                
            }else{
                list[index].classList.remove('text-flip');
            }
        }
        for (let index = 0; index < list2.length; index++) {
            if(direction === 'down'){
                list2[index].classList.toggle('position-reverse');
                list2[index].classList.remove(['text-flip-reverse']);
            }
        }
    }
    removeExpertiseClasses = (etat) =>{
        
        let expertises = document.getElementsByClassName('Exp_block');
        if(etat){
            for (let index = 0; index < expertises.length; index++) {
                expertises[index].classList.remove('expertise-animate-reverse'); 
                expertises[index].classList.remove('expertise-animate-reverse-2'); 
                expertises[index].classList.remove('expertise-animate-reverse-3');   
            }
        }else{
            for (let index = 0; index < expertises.length; index++) {
                expertises[index].classList.remove('expertise-animate');
                expertises[index].classList.remove('expertise-animate-2'); 
                expertises[index].classList.remove('expertise-animate-3');   
            }
        }
    }
    animateExpertise = (etat) =>{
        let expertises = document.getElementsByClassName('Exp_block');
        if(etat){
            for (let index = 0; index < expertises.length; index++) {
                expertises[index].classList.remove('expertise-animate-reverse'); 
                expertises[index].classList.remove('expertise-animate-reverse-2'); 
                expertises[index].classList.remove('expertise-animate-reverse-3'); 
                if(index=== 0 || index === 1){
                    expertises[index].classList.toggle('expertise-animate');
                }else if(index=== 2 || index === 3){
                    expertises[index].classList.toggle('expertise-animate-2');
                }
                else if(index=== 4 || index === 5){
                    expertises[index].classList.toggle('expertise-animate-3');
                }  
            }
        }else{
            for (let index = 0; index < expertises.length; index++) {
                expertises[index].classList.remove('expertise-animate');
                expertises[index].classList.remove('expertise-animate-2'); 
                expertises[index].classList.remove('expertise-animate-3'); 
                if(index=== 4 || index === 5){
                    expertises[index].classList.toggle('expertise-animate-reverse'); 
                }else if(index=== 2 || index === 3){
                    expertises[index].classList.toggle('expertise-animate-reverse-2');
                }
                else if(index=== 0 || index === 1){
                    expertises[index].classList.toggle('expertise-animate-reverse-3');
                }  
            }
        }
        
        
    }
    onLeave =(origin,destination,direction) => {
        if(origin){
            if(origin.index <= this.page.state.sectionCount && direction === 'down'){
                /******* animate the pagination dots ********** */
                   this.props.animateDots(origin.index + 1); 
                   
                /******* end animate the pagination dots ********** */

                /*******animate H1 ********** */
                    let currentSectionId = 'home-' + (destination.index+1);
    /* Reutilized */let currentSection =  document.getElementById(currentSectionId);
                    let spanList = currentSection.children[0].children[0].children[0].children;
                    
                    this.applyTextRotation('down', spanList);
                
                    
                    
                /*******end animate H1 ********** */

                /********** Animate mouse over **********/
               
                   
                    
                let blocks = document.getElementsByClassName('Exp_block');
                if(destination.index === 2){
                   
                       setTimeout(()=>{
                        for (let index = 0; index < blocks.length; index++) {
                            blocks[index].classList.remove('exp_block_hovered');
                           }
                       },700);
                }else{
                    
                    for (let index = 0; index < blocks.length; index++) {
                        blocks[index].classList.toggle('exp_block_hovered');
                    }
                }
                /********** End Animate mouse over **********/
                /*******animate Paragraph ********** */
                setTimeout(()=>{
                    let paragraph = currentSection.children[0].children[0].children[1];
                document.getElementsByName('para')[0].classList.remove('paragraph-disapear');
                document.getElementsByName('para')[1].classList.remove('paragraph-disapear');
                if(paragraph){
                    paragraph.classList.remove('paragraph-disapear');
                    paragraph.classList.remove('paragraph-disapear');
                    paragraph.classList.toggle('paragraph-disapear');
                }
                },200)
                
                /*******end animate Paragraph ********** */

                /******* animate Body BG ********** */
                
                    let percent = 100 / (this.page.state.sectionCount -1) ;
                    let v = (origin.index +1) * percent ;
                    document.body.style.backgroundPosition = "0% " + v +"%";
                   // document.body.style.backgroundSize =  "1" +v +"%"+" 100%";
                    
                /******* end animate Body BG ********** */


                /************** animate home-2 bg-image *************/
                if(destination.index === 1){
                    let bgImgDiv = document.getElementById('bgImg-home-2');
                
                    bgImgDiv.classList.toggle('animateBg'); 
                    
                }else{
                    let bgImgDiv = document.getElementById('bgImg-home-2');
                
                    bgImgDiv.classList.remove('animateBg');
                    bgImgDiv.classList.remove('animateBg');
                    
                }

                if(destination.index === 2 && window.innerWidth < 800){
                 this.animateExpertise(true);
                
                }else{
                    this.removeExpertiseClasses(true);
                }
                /************** end of animate home-2 bg-image *************/


                /************** animate reviews typing text *************/
                if(destination.index === (this.page.state.sectionCount -2)){
                   this.props.reviews.reviews.typed.reset();
                   this.props.reviews.reviews.thumbnail.classList.toggle('thumbnail_animation');    
                }
                /************** end of animate reviews typing text *************/


                /********** check for last section to switch navigation triangle ********/
                if(destination.index === (this.page.state.sectionCount -1)){
                    let triangle1 = document.getElementById('p-triangle1');
                    let triangle2 = document.getElementById('p-triangle2');
                    triangle2.style.display = 'flex';
                    triangle1.style.display = 'none';
                    let copySpan = document.getElementById('Skeleton-bottom-span');
                    copySpan.style.display = 'block';
                    let socialMedia = document.getElementById('socialMedia');
                    socialMedia.classList.remove('animateSocialMedia');
                    socialMedia.classList.toggle('animateSocialMediaReverse');
                /****************** Animate the last dot *********************/
                    let lastDot = document.getElementById("paginate-dot-" + (destination.index));
                    lastDot.classList.toggle('last-dot');
                /****************** Animating the last section *******************/
                   
                    
                    let contactH1 = document.getElementById('contactH1');
                    contactH1.children[0].children[0].classList.remove('text-flip');
                    contactH1.children[1].children[0].classList.remove('text-flip');
                    contactH1.children[0].children[0].classList.toggle('text-flip');
                    contactH1.children[1].children[0].classList.toggle('text-flip');
                    let intouch = document.getElementById('in-touch');
                    intouch.classList.toggle('animate-intouch');
                    let form = document.getElementById('form');
                    form.classList.toggle('animate-form');
                    let borderDiv = document.getElementsByClassName('bottom-border');
                    /* borderDiv[0].style.width = '50%';
                    borderDiv[1].style.width = '50%'; */
                    borderDiv[0].classList.toggle('widthForSpan');
                    borderDiv[1].classList.toggle('widthForSpan');
                }
                /********** End check for last section ********/
            } 
            if(origin.index > 0 && direction === 'up'){
                /******* animate the pagination dots ********** */
                  this.props.animateDots(origin.index - 1);
                  let lastDot = document.getElementById("paginate-dot-" + (this.page.state.sectionCount-1));
                  lastDot.classList.remove('last-dot');
                /******* end animate the pagination dots ********** */
                
                /*******animate H1 ********** */
                let currentSectionId = 'home-' + (destination.index +1);
                let currentSection =  document.getElementById(currentSectionId);
                let spanList = currentSection.children[0].children[0].children[0].children;
            
                   this.applyTextRotation('up',spanList);
              
                //this.applyTextRotation('up',spanList);
                /*******end animate H1 ********** */
               

                if(destination.index === 1){
                    let bgImgDiv = document.getElementById('bgImg-home-2');
                
                    bgImgDiv.classList.toggle('animateBg'); 
                    
                }else{
                    let bgImgDiv = document.getElementById('bgImg-home-2');
                
                    bgImgDiv.classList.remove('animateBg');
                    bgImgDiv.classList.remove('animateBg');
                    
                }
                /*******animate Paragraph ********** */
                setTimeout(()=>{
                    let paragraph = currentSection.children[0].children[0].children[1];
                document.getElementsByName('para')[0].classList.remove('paragraph-disapear');
                document.getElementsByName('para')[1].classList.remove('paragraph-disapear');
                if(paragraph){
                    paragraph.classList.remove('paragraph-disapear');
                    paragraph.classList.remove('paragraph-disapear');
                    paragraph.classList.toggle('paragraph-disapear');
                }
                },200)
                /* let paragraph = currentSection.children[0].children[0].children[1];
                document.getElementsByName('para')[0].classList.remove('paragraph-disapear');
                document.getElementsByName('para')[1].classList.remove('paragraph-disapear');
                if(paragraph){
                    paragraph.classList.remove('paragraph-disapear');
                    paragraph.classList.remove('paragraph-disapear');
                    paragraph.classList.toggle('paragraph-disapear');
                } */
                /*******end animate Paragraph ********** */

                /************** animate reviews typing text *************/
                if(destination.index === (this.page.state.sectionCount -2)){
                    this.props.reviews.reviews.typed.reset();
                    this.props.reviews.reviews.thumbnail.classList = ['']
                   this.props.reviews.reviews.thumbnail.classList = ['thumbnail_animation'];
                   let socialMedia = document.getElementById('socialMedia');
                   socialMedia.classList.replace('animateSocialMediaReverse','animateSocialMedia');
                   //socialMedia.classList.toggle('animateSocialMedia');
                }
                 /************** end of animate reviews typing text *************/

                /************** animate home-2 bg-image *************/
                if(destination.index === 2 && window.innerWidth < 800){
                    this.animateExpertise(false);
                      
                   }else{
                    this.removeExpertiseClasses(false);
                }
                /************** end of animate home-2 bg-image *************/

                let blocks = document.getElementsByClassName('Exp_block');
                if(destination.index === 2){
                   
                       setTimeout(()=>{
                        for (let index = 0; index < blocks.length; index++) {
                            blocks[index].classList.remove('exp_block_hovered');
                           }
                       },500);
                }else{
                    
                    for (let index = 0; index < blocks.length; index++) {
                        blocks[index].classList.toggle('exp_block_hovered');
                    }
                }
                /******* animate Body BG ********** */
                    let percent = 100 / (this.page.state.sectionCount -1);
                    let v = (origin.index -1) * percent ;
                    document.body.style.backgroundPosition = "0% " + (v) +"%";
                    //document.body.style.backgroundSize =  "1" +v +"%"+" ";
                /******* end animate Body BG ********** */
                
                /********** check for last section to switch navigation triangle ********/
                    let triangle1 = document.getElementById('p-triangle1');
                    let triangle2 = document.getElementById('p-triangle2');
                    triangle2.style.display = 'none';
                    triangle1.style.display = 'flex';
                    let copySpan = document.getElementById('Skeleton-bottom-span');
                    //let socialMedia = document.getElementById('socialMedia');
                    //socialMedia.classList.toggle('animateSocialMedia');
                    copySpan.style.display = 'none';
                    let borderDiv = document.getElementsByClassName('bottom-border');
                    borderDiv[0].classList.remove('widthForSpan');
                    borderDiv[1].classList.remove('widthForSpan');

                    let intouch = document.getElementById('in-touch');
                    intouch.classList.remove('animate-intouch');
                    let form = document.getElementById('form');
                    form.classList.remove('animate-form');
                /********** end of check for last section to switch navigation triangle ********/
  
            } 
        }    
    }
    
    render(){
        

        return (
            
            <div className="Container">
               <ReactFullpage 
                ref={ref => this.page = ref}
                scrollingSpeed={1000}
                onLeave={(origin, destination, direction) =>  this.onLeave(origin, destination, direction)}
                
                    render={({ state , fullpageApi }) => {
                        if(this.page ){
                            this.props.myFunction(this.page.state.sectionCount);
                        }
                    
                    return (
                        <ReactFullpage.Wrapper>
                            
                            {this.props.children}
                        </ReactFullpage.Wrapper>
                    );
                    }}
            />
            </div>
        );
    }
}

export default Container;