import React , {Component} from 'react';
import './clientsReviews.css';
import Clients from '../clients/clients';
import Reviews from '../reviews/reviews';
import sign from '../../assets/images/signature.png';
class ClientsReviews extends Component {
    
    state = {
        reviews : [
            {
                id : 1,
                name : 'ANAS KHARTIT',
                title : 'DG GREEN OIL',
                description : "Autant que client, je déclare mon entière satisfaction vis-à-vis des prestations de service reçues par votre agence Gudiz. L’application que vous avez créée répond exactement à mes attentes, Votre agence est absolument un choix à ne pas regretter.",
                signature : sign
            },
            {
                id : 2,
                name : 'YOUNNES ELASAL',
                title : 'DG ALIENS',
                description : "Gudiz est l’une des agences à fort potentiel à Marrakech, nous sommes très satisfaits des produits digitaux reçus de votre part. Notre système management s’est optimisé grâce à vos efforts !",
                signature : sign
            },
            {
                id : 3,
                name : 'DANIEL ROLLER',
                title : 'DG EMBASSY DIRECT UK',
                description : "J’ai collaboré avec plusieurs agences, mais la vôtre présente les offres les plus intéressantes avec des prix imbattables. J’ai reçu une prestation de qualité et j’en suis satisfait.",
                signature : sign
            }
            ,
            {
                id : 4,
                name : 'REDA JABRI',
                title : 'CO-FOUNDER SKWILAPP',
                description : "Gudiz est une équipe innovatrice et très talentueuse. Elle a pu mettre toutes nos idées dans la réalité. Le travail est bien structuré et achevé dans les délais prévus. De notre part, nous sommes très fiers de travailler avec votre agence et ça ne sera surtout pas la dernière fois.",
                signature : sign
            }
        ]
        ,
        currentIndex : 0
    }
    render(){
        
        return (
            <div id={this.props.id} className={"HomeSection section " + this.props.id}>
                <div className="div">
                    
                    <div className="clientsReviews">
                        <Clients />
                        <Reviews ref={ref => this.reviews = ref} reviews={this.state.reviews} currentIndex={this.state.currentIndex} />
                    </div>
                    
                </div>
                
            </div>
        );
    }
}

export default ClientsReviews;