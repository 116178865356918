import React , {Component} from 'react';
import './skwillapp.css';


import Header from '../../Components/Header/header';
import Contact from '../../Components/Contact/contact';
import skwilapp1 from '../../assets/images/skwillapp/note.png';
import skwilapp2 from '../../assets/images/skwillapp/note_2.png';
import skwilapp3 from '../../assets/images/skwillapp/profil.png';
import skwilapp4 from '../../assets/images/skwillapp/cadre_vide.png';
import skwilapp5 from '../../assets/images/skwillapp/feed_card.png';
class Skwillapp extends Component {
    
    
    


    render(){
         
        return (
            <div className="Skeleton" >
                <Header fromWork={true}/>
                
                <div className="ProjectDetail-section-1" name="blrDiv">
                    <div className="ProjectDetail-section-1-shadow"></div>
                    <p>Skwilapp 
                    <span>Application de gestion des écoles</span>
                    <span>ios/android</span>
                    </p>
                    <span class="hero__scroll" >
				   
				    <i class="chevron botttom"></i>
			        </span>
                </div>
                <div className="ProjectDetail-section-2" name="blrDiv">
                    <p>
                    Skwilapp permet aux écoles, enseignants, élèves et parents de rester
connectés à tout instant. Elle conduit une collaboration plus aisée entre tous
les intervenants dans le système scolaire grâce à ses différentes interfaces
permettant une communication fluide et une gestion efficace.

            <br/>
            <br/><h3>Points forts de Skwilapp</h3>
                    </p>
                </div>




                <div  className="ProjectDetail-section-3" name="blrDiv">

                    <div  className="ProjectDetail-type1">
                        <div data-aos="fade-left"   
                                data-aos-delay="100"
                                data-aos-easing="ease-in-out"
                                data-aos-mirror="true"
                                data-aos-once="false"
                                className="ProjectDetail-description">
                            <h4>Flux</h4>
                            <p> Flux mis à jour en temps réel,
Publications personnalisées publiées et partagées chaque jour.
Le mur d’actualités permet aux élèves et parents d’être informés et de suivre
chaque évènement, date d’évaluation, emploi du temps ou sorties, et de
publier à leurs tours, leurs photos de classe, projets et réalisations…</p>
                        </div>
                        <div data-aos="zoom-out" data-aos-id="super-duper2" className="ProjectDetail-image" 
                            data-aos-easing="ease-in-out"
                            data-aos-delay="100"
                            data-aos-mirror="true"
                            data-aos-once="false" >
                            <div className="my_fake_element" data-aos="zoom-in" data-aos-id="super-duper"></div>
                            <div className="ProjectDetail-section-4-mobile-container">
                                    <img src={skwilapp4} className="ProjectDetail-section-4-img-ctn"/>
                                    <div className="ProjectDetail-section-4-img-1">
                                        <img src={skwilapp5} id="image_scroll_1" />
                                    </div>
                                   {/*  <img src={skwilapp5} id="image_scroll_1" className="ProjectDetail-section-4-img-1"/> */}
                                </div>
                            </div>
                            
                    </div>




                    <div  className="ProjectDetail-type2">
                        <div data-aos="fade-right"
                            data-aos-delay="50"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false" 
                         className="ProjectDetail-description">
                            <h4>Emploie du temps</h4>
                            <p> C’est justement ce qu’un emploi du temps devrait être !
Générez, contrôlez, et visualisez les différentes séances, jours de vacances,
évaluations… mis à jour en temps réel.</p>
                        </div>
                        <div data-aos="zoom-out" className="ProjectDetail-image" style={{backgroundImage : `url(${skwilapp1})`}} 
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false" ></div>
                    </div>



                    <div  className="ProjectDetail-type1">
                        <div data-aos="fade-right"
                            data-aos-delay="50"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false" 
                         className="ProjectDetail-description">
                            <h4>Programme scolaire</h4>
                            <p> Ne vous perdez plus dans les pages ! Suivez en temps réel l’état
d’avancement du programme scolaire du ou des niveaux que vous choisissez. </p>
                        </div>
                        <div data-aos="zoom-out" className="ProjectDetail-image contain" style={{backgroundImage : `url(${skwilapp2})`}} 
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false" ></div>
                    </div>


                    <div  className="ProjectDetail-type2">
                        <div data-aos="fade-left" 
                            data-aos-delay="50"
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false" 
                            className="ProjectDetail-description">
                            <h4>Profil</h4>
                            <p>Que vous soyez enseignant, élève, parent ou administration, vous avez un
profil personnel qui vous permettra l’accès à toutes les interfaces où vous
intervenez. </p>
                        </div>
                        <div data-aos="zoom-out" className="ProjectDetail-image" style={{backgroundImage : `url(${skwilapp3})`}} 
                            data-aos-easing="ease-in-out"
                            data-aos-mirror="true"
                            data-aos-once="false" ></div>
                    </div>

                </div>


                {/* <div className="ProjectDetail-section-4" name="blrDiv" >
                        <div className="my_fake_element" data-aos="zoom-in" data-aos-id="super-duper"></div>
                        <div className="ProjectDetail-section-4-mobile-container">
                            <img src={skwilapp4} className="ProjectDetail-section-4-img-ctn"/>
                          <img src={skwilapp5} id="image_scroll_1" className="ProjectDetail-section-4-img-1"/>
                        </div>
                        <div className="my_fake_element" data-aos="zoom-out" data-aos-id="super-duper2"></div>
                       
                </div> */}





                <div className=" test-contact-container" name="blrDiv">
                <Contact fromWork={true} id="home-5" ref={ref => this.contact = ref} container={this.container}/> 
                <div className="contact-bg-bottom" name="blrDiv">
                            <div></div>
                            <span>Gudiz Studio &copy; 2019</span>
                            <div></div>
                        </div>
                </div>
                
                
            </div>
        );
    }
}

export default Skwillapp;