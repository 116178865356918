import React , {Component} from 'react';
import './reviews.css';
import Typed from 'react-typed';
import arrow from '../../assets/images/Website-Icons/ScrollUp.svg';
class Reviews extends Component {

   state = {
       index : 0
   }




    next = () =>{
        let newIndex = 0;
        if(this.state.index < (this.props.reviews.length -1 )){
            newIndex = this.state.index + 1;
            this.setState({index : newIndex });
            this.typed.reset();
        }
    
    }
    prev = () =>{
        let newIndex = 0;
        if(this.state.index > 0){
            newIndex = this.state.index - 1;
            this.setState({index : newIndex });
            this.typed.reset();
        }
        
    }
    componentDidMount(){
        
       
    }
    
    render() {
        let index = this.state.index;
        return (
            <div className="reviews">
               
                <div className="review">
                    <div className="thumbnail">
                        
                        <div ref={ref => this.thumbnail = ref}>
                            <h3 >{this.props.reviews[index].name}</h3>
                            <span>{this.props.reviews[index].title}</span>
                        </div>
                    </div>
                    <div className="about">
                        <p id="typing"><Typed typedRef={(typed) => { this.typed = typed; }}
                strings={[
                    this.props.reviews[index].description
                    ]}
                    typeSpeed={0}
                    backSpeed={20} 
                     >
                    
                </Typed></p>
                        
                        {/* <img src={this.props.reviews[index].signature} alt=""/> */}
                    </div>
                </div>
              <div ref={ref => this.reviewControls = ref} className="review-controls paragraph-disapear">
                <div className="prev" onClick={this.prev}>
                    <img src={arrow} />
                </div>
                <div className="next" onClick={this.next}>
                    <img src={arrow} />
                </div>
              </div>
                
        </div> 
        );
    }
}

export default Reviews;