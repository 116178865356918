import React , {Component} from 'react';
import './header.css';
import logo from '../../assets/images/Website-Icons/Gudiz-Print-Logo.svg';
import {NavLink} from 'react-router-dom';
import { inherits } from 'util';
class Header extends Component {

    state = {
        showNav : false
    };

    toggleHeader = (index) => {
        let etat = this.state.showNav ? false : true;   
        this.setState({showNav : etat}); 
        this.animateHeader(etat);
        if(index !== null){
            if(index === 0){
                this.props.refresh();
            }else{
                this.props.container.page.fullpageApi.moveTo(index);
            this.props.container.page.fullpageApi.moveSectionDown();
            }
            
        } 
    }
    blurBody = (etat) =>{
        if(this.props.fromWork){
            if(etat){
               
                
                document.getElementById('logo_img').classList.toggle('blur_body');
                let others = document.getElementsByName('blrDiv');
                for (let index = 0; index < others.length; index++) {
                    others[index].classList.toggle('blur_body')
                }
            }else{
                document.getElementById('logo_img').classList.remove('blur_body');
                let others = document.getElementsByName('blrDiv');
                for (let index = 0; index < others.length; index++) {
                    others[index].classList.remove('blur_body')
                }
            }
        }else{
            if(etat){
                document.getElementsByClassName('Container')[0].classList.toggle('blur_body');
                document.getElementById('socialMedia').classList.toggle('blur_body');
                document.getElementById('paginate').classList.toggle('blur_body');
                document.getElementById('logo_img').classList.toggle('blur_body');
                let others = document.getElementsByName('blrDiv');
                for (let index = 0; index < others.length; index++) {
                    others[index].classList.toggle('blur_body')
                }
            }else{
                document.getElementsByClassName('Container')[0].classList.remove('blur_body');
                document.getElementById('socialMedia').classList.remove('blur_body');
                document.getElementById('paginate').classList.remove('blur_body');
                document.getElementById('logo_img').classList.remove('blur_body');
                let others = document.getElementsByName('blrDiv');
                for (let index = 0; index < others.length; index++) {
                    others[index].classList.remove('blur_body')
                }
            }
        
        }
    }
    animateHeader = (etat) => {
        let nav = document.getElementById('header-nav');
        let headerDots = document.getElementsByClassName('header-dots');
        if(etat){
            nav.classList.remove('hide_nav');
            nav.classList.toggle('show-nav');
            nav.children[0].style.display = 'flex';
            headerDots[0].style.opacity = 0;
            this.blurBody(true);
            setTimeout( () => {
                headerDots[0].style.opacity = 1
            },2000);
            for (let index = 0; index < headerDots.length; index++) {
                headerDots[index].classList.toggle('hovered-Header-dots'); 
            }
        }else{
            nav.classList.remove('show-nav');
            nav.classList.toggle('hide_nav');
            this.blurBody(false);
            nav.children[0].style.display = 'none';
            headerDots[0].style.opacity = 1;
            for (let index = 0; index < headerDots.length; index++) {
                headerDots[index].classList.toggle('hovered-Header-dots'); 
            }
        }

    }


    
    render(){
        return (
            <div className="Header" >
                <div className="header-shadow"></div>
                <div className="Header-content">
                    <div className="left-log" name="blrDiv"></div>
                    <NavLink to="/" onClick={this.props.refresh}><img src={logo} alt="" id="logo_img" /></NavLink>
                    <div className="right-log" name="blrDiv"></div>
                    <div className="header-dots" onClick={() => this.toggleHeader(null)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="after-dots" name="blrDiv"></div>
                   <nav id="header-nav">
                        <ul style={{display: 'none'}}>
                        
                            <li><NavLink to="/" onClick={() => this.toggleHeader(0)} className="Header-Accueil">Accueil</NavLink></li>

                            <li><NavLink to="/a_propos" onClick={() => this.toggleHeader(0)} className="Header-About">À propos</NavLink></li>
                            
                            <li><NavLink to="/" onClick={() => this.toggleHeader(2)} className="Header-Expertise">Expertise</NavLink></li>

                            <li><NavLink to="/projets" onClick={() => this.toggleHeader(2)} className="Header-Work">Projets</NavLink></li>
                            
                            <li><NavLink to="/" onClick={() => this.toggleHeader(4)} className="Header-Contact">Contact</NavLink></li>
                        </ul>
                    </nav> 
                </div>
              
            </div>
        );
    }
}

export default Header;