import React , {Component} from 'react';
import './work.css';


import Header from '../../Components/Header/header';
import WorkProject from '../../Components/workProject/workProject';
import Contact from '../../Components/Contact/contact';
import skwilapp from '../../assets/images/Work/project-1.png';
import potoon from '../../assets/images/Work/Greenoil.jpg';
import nomo from '../../assets/images/Work/project-3.png';

import {NavLink} from 'react-router-dom';

class Skeleton extends Component {
    
    constructor(props){
        super(props);
        this.state={
        }
       
        
    }
    
    clicked = (param) =>{
        if(param == 1){
            window.scrollTo(0,0);
             this.props.history.push('/projets/skwillapp')
        }else if(param == 2){

        }else{

        }
    }


    render(){
         
        return (
            <div className="Skeleton">
                <Header fromWork={true}/>
               
                
                   
                <div className="work-section-1" name="blrDiv">
                    <div className="work-section-1-shadow">
                        <p>Produits beaux, modernes,<br/> efficaces tout en<br/> étant convivial</p>
                        <span class="hero__scroll" >
				   
				    <i class="chevron botttom"></i>
			        </span>
                    </div>
                </div>
                
                <div className="work-section-2" name="blrDiv">
                    <div className="work-section-2-intro">
                        <p>
                            Nous concevons et développons des produits digitaux efficaces et révolutionnaires. Grâce à
                            notre équipe d’experts en digital, nous poussons les limites du génie et du design pour délivrer
                            les produits qui feront la différence.
                        </p>
                    </div>
                    <WorkProject image={skwilapp} alt="Skwilapp" clicked={()=>{this.clicked(1)}} title="Skwilapp" description="Skwilapp permet aux écoles, enseignants, élèves et parents de rester
connectés à tout instant. Elle conduit une collaboration plus aisée entre tous
les intervenants dans le système scolaire grâce à ses différentes interfaces
permettant une communication fluide et une gestion efficace." />
                    <WorkProject image={potoon} alt="Green Oil" clicked={()=>{this.clicked(2)}} title="Green Oil" description="L’application Green Oil vous permet de vous localiser et de trouver sur une carte graphique toutes les stations carburants disponibles à proximité de votre emplacement ou dans la destination où vous souhaitez aller." />
                    <WorkProject image={nomo} alt="Nomo" clicked={()=>{this.clicked(3)}} title="Nomo" description="Travaux en cours..." />
                    <div className="work-section-2-bottom-space"></div>
                    
                    
                </div>
                <div className=" test-contact-container" name="blrDiv">
                <Contact fromWork={true} id="home-5" ref={ref => this.contact = ref} container={this.container}/> 
                <div className="contact-bg-bottom" name="blrDiv">
                            <div></div>
                            <span>Gudiz Studio &copy; 2019</span>
                            <div></div>
                        </div>
                </div>
                
                
                
            </div>
        );
    }
}

export default Skeleton;