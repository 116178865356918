import React , {Component} from 'react';
import './About.css';

import habib from '../../assets/images/About/habib_1.png'
import gudizteam from '../../assets/images/About/gudizteam.png'
import amine from '../../assets/images/About/amine_1.png'
import soufian from '../../assets/images/About/soufian_1.png'

import Header from '../../Components/Header/header'
import Contact from '../../Components/Contact/contact'
import AboutSection from '../../Components/aboutSection/aboutSection';

import ReactFullpage from '@fullpage/react-fullpage';
class Skeleton extends Component {
    
    state ={

    }

    onLeave =(origin,destination,direction) => {
        if(origin ){
            if(origin.index <= this.page.state.sectionCount && direction === 'down'){
                if(destination.index === (this.page.state.sectionCount -1)){
                    
                    let contactH1 = document.getElementById('contactH1');
                    contactH1.children[0].children[0].classList.remove('text-flip');
                    contactH1.children[1].children[0].classList.remove('text-flip');
                    contactH1.children[0].children[0].classList.toggle('text-flip');
                    contactH1.children[1].children[0].classList.toggle('text-flip');
                    let intouch = document.getElementById('in-touch');
                    intouch.classList.toggle('animate-intouch');
                    let form = document.getElementById('form');
                    form.classList.toggle('animate-form');
                   
                }
            }
            if(origin.index > 0 && direction === 'up'){
                setTimeout(()=>{
                    let intouch = document.getElementById('in-touch');
                    intouch.classList.remove('animate-intouch');
                    let form = document.getElementById('form');
                    form.classList.remove('animate-form');
                },400)
                
            }
        }
    }



    render(){
         
        return (
            <div className="Skeleton">
                <Header fromWork={true}/>
                <ReactFullpage 
                    ref={ref => this.page = ref}
                    scrollingSpeed={1000}
                    onLeave={(origin, destination, direction) =>  this.onLeave(origin, destination, direction)}
                    render={({ state , fullpageApi }) => {
                        
                    
                    return (
                        <ReactFullpage.Wrapper>
                            <div className="section About-section-1" name="blrDiv">
                    <div className="About-section-1-shadow">
                        <p>Engagés à toujours produire<br/> des résultats qui satisfont<br/> les attentes de nos partenaires.</p>
                    </div>
                </div>
                <div className="section About-section-2" name="blrDiv">
                    <div className="insideDiv">
                    <span></span>
                        
                    <p> Avant d’être une entreprise, Gudiz est une passion. La passion de créer des solutions intelligentes, efficaces et surtout à très grande valeur ajoutée pour nos partenaires. La passion d’évoluer chaque jour, de se surpasser, et de développer des produits digitaux performants.</p>
                    </div>
                       
                    
                </div>
                <AboutSection image={gudizteam}/>
                
                <AboutSection image={habib} alt="Eloufraoui Lahbib" title="Eloufraoui Lahbib"
                subTitle="Co-Founder & Product Manager" description="En tant que CEO de l’agence Gudiz, je garantie la satisfaction de nos clients en supervisant moi-même la réalisation de chaque solution, pour livrer un produit dont nous serons fiers. Nous avons bâti notre agence autour d’un principe : Fais le bien ou ne le fais pas. Chaque engagement de réaliser un projet, renvoie à un engagement de meilleur service et meilleure qualité.
                "/>
                <AboutSection image={amine} alt="Mohamed Amine Assil" title="Mohamed Amine Assil"
                subTitle="Co-Founder & CTO" description="Par souci de satisfaction des clients, nous nous positionnons en tant qu’utilisateur durant tout le processus de réalisation des solutions it ; de la conception graphique, jusqu’à la livraison du produit final. Afin d’assurer la meilleure expérience utilisateur possible."/>
                <AboutSection image={soufian} alt="Soufiane Elmaskaly" title="Soufiane Elmaskaly"
                subTitle="Co-Founder & Marketing Manager" description="Passionnés par notre métier, nous faisons de notre savoir-faire un tremplin de la réussite et la compétitivité de nos clients. Depuis 2015, notre équipe a su développer un vrai sens de l’écoute, du besoin du client, des exigences du marché, et des nouveautés dans le domaine du web et technologies associées."/>
                <Contact id="home-5"  fromAbout={true} ref={ref => this.contact = ref} container={this.container}/>
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
                
            </div>
            
        );
    }
}

export default Skeleton;