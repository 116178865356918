import React, { Component } from 'react';
import './App.css';
import Skeleton from './Components/skeleton/skeleton';
import Skeleton2 from './Containers/Work/work';
import Skeleton3 from './Containers/About/About';
import Skeleton4 from './Containers/Skwillapp/skwillapp';
import {Route , Switch , withRouter} from 'react-router-dom';
import './assets/js/js';
import $ from 'jquery';
class App extends Component {
 
  componentDidMount(){
    $(document).ready(function(){
      $('.hero__scroll').on('click', function(e) {
				$('html, body').animate({
					scrollTop: $(window).height()
				}, 1200);
			});
    });
  }
  render() {
    return (
      <div className="App" id="App">
        <Switch>
            <Route path="/" exact  component={Skeleton}/>
            <Route path="/projets" exact  component={Skeleton2}/>
            <Route path="/a_propos" exact  component={Skeleton3}/>
            <Route path="/projets/skwillapp" exact  component={Skeleton4}/>
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
